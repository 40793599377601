import React, { useEffect, useState } from "react";
import contact from "./contact.module.css";

import Banner from "../../../assets/Contact/contact.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "../Form/Form";
import { createPortal } from "react-dom";
import {
  faXTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import MyService from "../../../MyService";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Portal from "./Portal";

//  Validation for form
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
console.log("validations", phoneRegExp);
const Schema = Yup.object().shape({
  fname: Yup.string()
    .min(3, "Full Name must be at least 3 characters")
    .required("Full Name is required"),
  name: Yup.string()
    .min(3, "Last Name must be at least 3 characters")
    .required("Last Name is required"),
  email: Yup.string()
    .email("Invalid Email format")
    .required("Email is required"),
  text: Yup.string()
    .min(10, "Minimum 10 character is required")
    .required("Message is required"),
  //   phoneNumber: Yup.string()
  //     .required("Phone Number is required")
  //     .matches(phoneRegExp, 'Phone number is not valid')
  //     .min(10, "Phone Number is too short")
  //     .max(15, "Phone Number is too long"),
  // });
  phoneNumber: Yup.string()
    .matches(/^[0-9+]+$/, "Phone number is not valid ")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Maximum 15 digits allowed ")
    .required("Phone number is required"),
});

function Contact() {
  //  States
  const [isLoading, setisLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [token, setToken] = useState("");

  // dynamically content

  const [skeletonOn, setSkeletonOn] = useState(true);
  const [contentSection1, setContentSection1] = useState({});
  const [contentSection2, setContentSection2] = useState({});
  const [contentSection3, setContentSection3] = useState({});
  const [contentSection4, setContentSection4] = useState({});
  const [contentSection5, setContentSection5] = useState({});
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const GetAbout = async () => {
      const resp = await MyService.gettingData({ pageId: 6 }, "OCMTContent");
      console.log("Contact response", resp.data.dataItems);
      let sectionItem = resp.data.dataItems;
      sectionItem.forEach((element) => {
        console.log("Element data", element.data.sectionId);
        if (element.data.sectionId === 1) {
          setContentSection1(element.data);
          console.log("content data 1", contentSection1);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 2) {
          setContentSection2(element.data);
          console.log("content data 2", contentSection2);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 3) {
          setContentSection3(element.data);
          console.log("content data 3", contentSection3);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 4) {
          setContentSection4(element.data);
          console.log("content data 4", contentSection4);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 5) {
          setContentSection5(element.data);
          console.log("content data 5", contentSection5);
          setSkeletonOn(false);
        }
      });
    };

    GetAbout();
  }, []);

  // dynamically content
  useEffect(() => {
    const gettingToken = async () => {
      const response = await axios.get(
        "https://ocmt-backend.vercel.app/refresh-token"
      );
      // console.log("Token API Get Response",response.data.response.access_token)
      const updatedToken = response.data.response.access_token;
      console.log("Updated Token", updatedToken);
      setToken(updatedToken);
      // token=updatedToken;
    };

    gettingToken();
  }, []);
  useEffect(() => {
    const timerFuction = setTimeout(() => {
      if (downLoading) {
        setDownLoading(false);
      }
    }, 2000);
    return () => {
      clearTimeout(timerFuction);
    };
  }, [downLoading]);
  // const token ="OAUTH2.eyJraWQiOiJkZ0x3cjNRMCIsImFsZyI6IkhTMjU2In0.eyJkYXRhIjoie1wiaWRcIjpcImU1NTBjZDIxLTY5N2QtNDQxNy04YmNjLTVmNGY5MWEwMmRjOFwifSIsImlhdCI6MTY5NzEyNTk4NiwiZXhwIjoxNzYwMTk3OTg2fQ.v6M6HFLKtmOZ4UQ59y3G_gCqeDYps6avv_hqwAyu-zw";
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    isValid,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: {
      email: "",
      text: "",
      name: "",
      fname: "",
      phoneNumber: "",
    },
    validationSchema: Schema,
    validateOnMount: true,
    onSubmit: async (values, action) => {
      console.log("form values formik", values);
      const versalBody = {
        to: "franchise@ocmtontario.ca",
        subject: `OCMT contact email form ${values.fname}  ${values.name}`,
        text: values.text,
      };
      const body = {
        dataCollectionId: "Contacts",
        dataItem: {
          data: {
            firstName: values.fname,
            lastName: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            message: values.text,
          },
        },
      };
      try {
        setisLoading(true);
        const response = await axios.post(
          "https://www.wixapis.com/wix-data/v2/items",
          body,
          {
            headers: {
              Authorization: ` ${token}`,
              "Content-Type": "application/json", // Optional, set the content type based on your API requirements
            },
          }
        );

        console.log("contact API response", response);
        console.log("body of API REquest", body);
        if (response.status === 200) {
          // toast.success('Thanks for contacting us !')
          const resp = await axios.post(
            "https://ocmt-backend.vercel.app/send-email",
            versalBody
          );
          if (resp.data.success) {
            toast.success("Thank you for reaching out to us");
            setisLoading(false);
            action.resetForm();
          }
          console.log("versal response", resp);
        }
      } catch (errors) {
        toast.error("Something went wrong !");
        setisLoading(false);
        console.log("catch error", errors);
        action.resetForm();
      }
      action.resetForm();
    },
  });
  const condition = !(errors && isValid);
  console.log("condition is ", condition);
  console.log(" Errors , isValid", errors, isValid);
  console.log("Open state", isOpen);

  const handle = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen &&
        createPortal(
          <div className={contact.portal}>
            <Form open={setIsOpen} />
          </div>,
          document.getElementById("portal")
        )}

      <div>
        <section className={contact.contactus_section}>
          <div className={contact.img_row}>
            {!skeletonOn ? (
              <img
                src={MyService.imageConverter(contentSection1.image)}
                alt="banner"
              />
            ) : (
              <Skeleton
                count={12}
                className={contact.img_row}
                style={{ height: "20px", width: "100%", margin: "10px 0px" }}
              />
            )}
            <div className={contact.white_text_box}>
              <div className={contact.Stem_heading} data-aos="fade-up">
                {!skeletonOn ? (
                  contentSection1.heading
                ) : (
                  <Skeleton className={contact.topHeading} />
                )}
              </div>
            </div>
          </div>
          <div className={`${contact.container_details}`}>
            <div className="row mx-0">
              <div className="col-12 col-md-6 col-lg-4 px-0">
                <div className={contact.address_details}>
                  <div
                    className={`${contact.address} w-100 text-center`}
                    data-aos="fade-up"
                  >
                    {!skeletonOn ? (
                      contentSection2.heading
                    ) : (
                      <Skeleton
                        className={contact.address}
                        style={{ height: "60px", width: "50%" }}
                      />
                    )}
                  </div>
                  <div
                    className={`${contact.padding_ajy} w-100 text-center`}
                    data-aos="fade-up"
                  >
                    {!skeletonOn ? (
                      contentSection2.paragraphText
                    ) : (
                      <Skeleton
                        className={contact.padding_ajy}
                        style={{ height: "40px", width: "70%" }}
                      />
                    )}
                  </div>
                  <div
                    className={`${contact.padding_ajy} w-100 text-center`}
                    data-aos="fade-up"
                  >
                    {!skeletonOn ? (
                      contentSection2.paragraph2
                    ) : (
                      <Skeleton
                        count={2}
                        className={contact.padding_ajy}
                        style={{
                          height: "40px",
                          width: "70%",
                          marginTop: "20px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 px-0">
                <div className={contact.address_details}>
                  <div
                    className={`${contact.address} w-100 text-center`}
                    data-aos="fade-up"
                  >
                    {!skeletonOn ? (
                      contentSection3.heading
                    ) : (
                      <Skeleton
                        className={contact.address}
                        style={{ height: "60px", width: "50%" }}
                      />
                    )}
                  </div>
                  <div className={contact.padding_ajy} data-aos="fade-up"></div>
                  <a href="mailto:franchise@ocmtontario.ca" style={{textDecoration:'none',color:'black'}}>
                    <div
                      className={`${contact.padding_ajy} w-100 text-center`}
                      data-aos="fade-up"
                    >
                      {!skeletonOn?( <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ marginRight: "8px" }}
                      />):(<Skeleton count={1}/>)}
                     
                      {!skeletonOn ? (
                        contentSection3.paragraphText
                      ) : (
                        <Skeleton
                          count={3}
                          className={contact.padding_ajy}
                          style={{
                            height: "40px",
                            width: "70%",
                            marginBottom: "20px",
                          }}
                        />
                      )}
                    </div>
                  </a>
                  {!skeletonOn ? (
                    <div className={contact.icons}>
                      <a
                        href="https://www.ocmtontario.ca"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#8b0000" }}
                      >
                        <div className={contact.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faGlobe} />
                        </div>
                      </a>
                      <a
                        href="https://www.youtube.com/OCMTChannel"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#8b0000" }}
                      >
                        <div className={contact.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faYoutube} />
                        </div>
                      </a>
                      <a
                        href="https://www.facebook.com/Ocmt.canada"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#8b0000" }}
                      >
                        <div className={contact.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </div>
                      </a>
                      <a
                        href="https://www.instagram.com/ocmt.canada"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#8b0000" }}
                      >
                        <div className={contact.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faInstagram} />
                        </div>
                      </a>
                      <a
                        href="https://twitter.com/CanadaOcmt"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#8b0000" }}
                      >
                        <div className={contact.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faXTwitter} />
                        </div>
                      </a>
                    </div>
                  ) : (
                    <Skeleton
                      count={3}
                      className={contact.padding_ajy}
                      style={{
                        height: "40px",
                        width: "70%",
                        marginTop: "20px",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 px-0">
                <div className={contact.address_details}>
                  <div
                    className={`${contact.address} w-100 text-center`}
                    data-aos="fade-up"
                  >
                    {!skeletonOn ? (
                      contentSection4.heading
                    ) : (
                      <Skeleton
                        className={contact.address}
                        style={{ height: "60px", width: "50%" }}
                      />
                    )}
                  </div>
                  <div
                    className={`${contact.padding_ajy} w-100 text-center`}
                    data-aos="fade-up"
                  >
                    {!skeletonOn ? (
                      contentSection4.paragraph2
                    ) : (
                      <Skeleton
                        className={contact.padding_ajy}
                        style={{ height: "40px", width: "70%" }}
                      />
                    )}
                  </div>
                  <div
                    className={`${contact.padding_ajy} w-100 text-center`}
                    data-aos="fade-up"
                  >
                    {!skeletonOn ? (
                      contentSection4.paragraphText
                    ) : (
                      <Skeleton
                        count={2}
                        className={contact.padding_ajy}
                        style={{
                          height: "40px",
                          width: "70%",
                          marginTop: "20px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={contact.download_box}>
            <div className={contact.download_details}>
              <div className={contact.brochure} data-aos="fade-up">
                {!skeletonOn ? (
                  contentSection5.heading
                ) : (
                  <Skeleton
                    count={1}
                    className={contact.brochure}
                    style={{ height: "60px", width: "70%", marginTop: "20px" }}
                  />
                )}
              </div>
              <div className={contact.brochure_text} data-aos="fade-up">
                {!skeletonOn ? (
                  contentSection5.paragraphText
                ) : (
                  <Skeleton
                    count={2}
                    className={contact.padding_ajy}
                    style={{ height: "40px", width: "100%", marginTop: "20px" }}
                  />
                )}
              </div>

              {!skeletonOn ? (
                <button
                  className={contact.brochure_button}
                  onClick={() => handle()}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  {downLoading ? (
                    <i
                      className="fa fa-spin fa-spinner"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  ) : (
                    `${contentSection5.button}`
                  )}
                </button>
              ) : (
                <Skeleton
                  count={1}
                  className={contact.padding_ajy}
                  style={{ height: "50px", width: "30%", marginTop: "20px" }}
                />
              )}
            </div>
          </div>
          <div className={`${contact.form_container} row mx-0`}>
            <form
              className="col-md-6 order-2 order-md-2 px-0"
              onSubmit={handleSubmit}
              data-aos="fade-up"
            >
              <div className={contact.form_main}>
                <div className="w-100">
                  <label className={`${contact.label_name} form-label`}>
                    First Name
                  </label>
                  <input
                    type="text"
                    name="fname"
                    className={`${contact.text_input} form-control`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fname}
                  />
                  {errors.fname && touched.fname ? (
                    <p className={contact.contact_errors}>{errors.fname}</p>
                  ) : null}
                </div>
                <div className="w-100">
                  <label className={`${contact.label_name} form-label`}>
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`${contact.text_input} form-control`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name ? (
                    <p className={contact.contact_errors}>{errors.name}</p>
                  ) : null}
                </div>

                <div className="w-100">
                  <label className={`${contact.label_name} form-label`}>
                    Phone Number *
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    className={`${contact.text_input} form-control`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minLength="10"
                    maxLength="15"
                    value={values.phoneNumber}
                  />
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <p className={contact.contact_errors}>
                      {errors.phoneNumber}
                    </p>
                  ) : null}
                </div>

                <div className="w-100">
                  <label className={`${contact.label_name} form-label`}>
                    Email *
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={`${contact.text_input} form-control`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <p className={contact.contact_errors}>{errors.email}</p>
                  ) : null}
                </div>

                <div className="w-100">
                  <label className={`${contact.label_name}  form-label`}>
                    Message
                  </label>
                  <textarea
                    name="text"
                    id=""
                    className={` ${contact.message} form-control w-100`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.text}
                  ></textarea>
                  {errors.text && touched.text ? (
                    <p className={contact.contact_errors}>{errors.text}</p>
                  ) : null}
                </div>

                <button
                  className={
                    !(errors && isValid)
                      ? `${contact.disableButton} mt-3`
                      : `${contact.brochure_button} mt-3`
                  }
                  type="submit"
                  disabled={!(errors && isValid)}
                  // data-aos="fade-up"
                >
                  {isLoading ? (
                    <i
                      className="fa fa-spin fa-spinner"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  ) : (
                    "Send"
                  )}
                </button>
              </div>
            </form>
            <div className="col-md-6 order-1 order-md-1 px-0">
              <div className={contact.map_box} data-aos="fade-up">
                <iframe
                  title="canda Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.926274129132!2d-79.25504622522482!3d43.753627745931745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d1cef21cc7b5%3A0x8abc98c802b6936!2s2901%20Lawrence%20Ave%20E%2C%20Scarborough%2C%20ON%20M1P%202T4%2C%20Canada!5e0!3m2!1sen!2s!4v1696831416236!5m2!1sen!2s"
                  width="600"
                  height="450"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}

export default Contact;
