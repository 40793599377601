import React from "react";
import { Routes, Route } from "react-router-dom";
import About from './Components/Pages/About/About';
import GlobalPartnership from './Components/Pages/Global Partnership/GlobalPartnership';
import Opportunities from './Components/Pages/Opportunities/Opportunities';
import Franchisee from './Components/Pages/Franchisee/Franchisee';
import Contact from './Components/Pages/Contact/Contact'
import Home from './Components/Pages/Home/Home';
import Form from "./Components/Pages/Form/Form";
import RootLayout from "./RootLayout";
const MainRoutes = () => {
  return (
    <>
        <Routes>
        <Route path="/" element={<RootLayout/>}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/globalpartnerships" element={<GlobalPartnership />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/franchisee" element={<Franchisee />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/form" element={<Form />} />
        </Route>
        </Routes>
    </>
  );
};

export default MainRoutes;
