import React, { useState } from "react";
import Headercss from "./header.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// import Form from "../../Pages/Form/Form";
import "animate.css";
const Header = ({ headerData, headText, skeletonOn }) => {
  const location = useLocation();
  const [barShow, setBarSHow] = useState(false);
  // console.log('footer Map Data ',footer)
  return (
    <>
      <header className={Headercss.mainHeaader}>
        <nav className={Headercss.navbar_style}>
          <div
            className={Headercss.hamburger_icon}
            onClick={() => {
              setBarSHow(!barShow);
            }}
          >
            {!barShow ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faClose} />
            )}
          </div>
          {barShow && (
            <ul className={`${Headercss.header_items} d-sm-none d-flex`}>
              {headerData.map((item, index) => {
                const { text, urls } = item;
                return (
                  <li className={Headercss.header_item} key={index}>
                    <NavLink
                      className={
                        location.pathname === urls
                          ? Headercss.active
                          : Headercss.header_link
                      }
                      to={urls}
                      onClick={()=>setBarSHow(false)}
                    >
                      {skeletonOn ? (
                        text
                      ) : (
                        <Skeleton
                          count={1}
                          className={
                            location.pathname === urls
                              ? Headercss.active
                              : Headercss.header_link
                          }
                          style={{
                            height: "40px",
                            width: "100%",
                          }}
                        />
                      )}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          )}
          <ul className={`${Headercss.header_items} d-none d-sm-flex`}>
            {headerData.map((item, index) => {
              const { text, urls } = item;
              return (
                <li className={Headercss.header_item} key={index}>
                  <NavLink
                    className={
                      location.pathname === urls
                        ? Headercss.active
                        : Headercss.header_link
                    }
                    to={urls}
                  >
                    {skeletonOn ? (
                      text
                    ) : (
                      <Skeleton
                        count={1}
                        className={
                          location.pathname === urls
                            ? Headercss.active
                            : Headercss.header_link
                        }
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                      />
                    )}
                  </NavLink>
                </li>
              );
            })}
          </ul>

          {location.pathname === "/" ? (
            <div className={Headercss.heading_box}>
              <div
                className={`${Headercss.heading} w-100 text-center animate__animated animate__bounceInLeft`}
              >
                {skeletonOn ? (
                  headText
                ) : (
                  <Skeleton
                    count={1}
                    className={`${Headercss.heading_box} mx-auto`}
                    style={{
                      height: "10px",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            </div>
          ) : null}
        </nav>
      </header>
    </>
  );
};

export default Header;
