import React, { useEffect, useState } from "react";

import Style from "./about.module.css";
import friends from "../../../assets/About/friends.webp";
import ourVin from "../../../assets/About/ourVision.webp";
import MyService from "../../../MyService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Aos from "aos";
import "aos/dist/aos.css";
const About = () => {
  const [skeletonOn, setSkeletonOn] = useState(true);
  const [contentSection1, setContentSection1] = useState({});
  const [contentSection2, setContentSection2] = useState({});
  const [contentSection3, setContentSection3] = useState({});
  const [contentSection4, setContentSection4] = useState({});
  const [contentSection5, setContentSection5] = useState({});
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const GetAbout = async () => {
      const resp = await MyService.gettingData({ pageId: 2 }, "OCMTContent");

      let sectionItem = resp.data.dataItems;
      // let contentSection1 = {}
      // let contentSection2 = {}
      // let contentSection3 = {}
      // let contentSection4 = {}
      // let contentSection5 = {}
      sectionItem.forEach((element) => {
        console.log("Element data", element.data.sectionId);
        if (element.data.sectionId === 1) {
          setContentSection1(element.data);

          setSkeletonOn(false);
        } else if (element.data.sectionId === 2) {
          setContentSection2(element.data);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 3) {
          setContentSection3(element.data);

          setSkeletonOn(false);
        } else if (element.data.sectionId === 4) {
          setContentSection4(element.data);

          setSkeletonOn(false);
        } else if (element.data.sectionId === 5) {
          setContentSection5(element.data);

          setSkeletonOn(false);
        }
      });
    };

    GetAbout();
  }, []);
  return (
    <>
      <div className={Style.main}>
        <div className={Style.wrapper}>
          <div className={Style.topContentBox}>
            <div className="w-100 mx-auto">
              <h1 className={Style.topHeading}>
                {!skeletonOn ? (
                  contentSection1.heading
                ) : (
                  <Skeleton
                    className={Style.topHeading}
                    style={{ height: "60px", width: "50%" }}
                  />
                )}
              </h1>
            </div>
            <p className={Style.topBoxPara} data-aos="">
              {!skeletonOn ? (
                contentSection1.paragraphText
              ) : (
                <Skeleton
                  count={2}
                  className={Style.topBoxPara}
                  style={{ height: "40px", width: "100%" }}
                />
              )}
            </p>

            <p className={Style.topBoxParaTwo} data-aos="fade-up">
              {!skeletonOn ? (
                contentSection1.paragraph2
              ) : (
                <Skeleton
                  count={2}
                  className={Style.topBoxParaTwo}
                  style={{ height: "40px", width: "100%" }}
                />
              )}
            </p>
            <p className={Style.topBoxParathree} data-aos="fade-up">
              {!skeletonOn ? (
                contentSection1.paragraph3
              ) : (
                <Skeleton
                  count={2}
                  className={Style.topBoxParathree}
                  style={{ height: "40px", width: "100%" }}
                />
              )}
            </p>
          </div>
          {/*  Second Box */}
          <div className={Style.imageContentBox} data-aos="fade-up">
            <p className={Style.imagePara}>
              {!skeletonOn ? (
                contentSection2.paragraphText
              ) : (
                <>
                  <Skeleton
                    style={{
                      marginTop: "20px",
                      transform: "translateY(-50%)",
                      height: "50px",
                    }}
                  />
                  <Skeleton
                    style={{
                      marginTop: "20px",
                      transform: "translateY(-50%)",
                      height: "50px",
                    }}
                  />
                  <Skeleton
                    style={{
                      marginTop: "20px",
                      transform: "translateY(-50%)",
                      height: "50px",
                    }}
                  />
                  <Skeleton
                    style={{
                      marginTop: "20px",
                      transform: "translateY(-50%)",
                      height: "50px",
                    }}
                  />
                  <Skeleton
                    style={{
                      marginTop: "20px",
                      transform: "translateY(-50%)",
                      height: "50px",
                    }}
                  />
                </>
              )}
            </p>
          </div>
          {/* Third Box Our Mission */}
          <section className={Style.Stem2_program}>
            <div className="row mx-0">
              <div className="col-md-6 order-md-1 order-1 px-0">
                <div className={Style.stemImages_box}>
                  <div className={Style.new_box} data-aos="fade-up">
                    {!skeletonOn ? (
                      <img
                        src={MyService.imageConverter(contentSection3.image)}
                        alt="Corporate Business"
                      />
                    ) : (
                      <Skeleton className={Style.new_box} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-md-2 order-2 px-0">
                <div className={Style.Stem_program_box}>
                  <div className={Style.Stem_heading} data-aos="fade-up">
                    {!skeletonOn ? (
                      contentSection3.heading
                    ) : (
                      <Skeleton
                        className={Style.Stem_heading}
                        style={{ height: "50px", width: "50%" }}
                      />
                    )}
                  </div>
                  <div className={Style.content_box}>
                    <div className={Style.Stem_text} data-aos="fade-up">
                      {!skeletonOn ? (
                        contentSection3.paragraphText
                      ) : (
                        <>
                          {" "}
                          <Skeleton
                            className={Style.Stem_text}
                            style={{ height: "40px", width: "100%" }}
                          />
                          <Skeleton
                            className={Style.Stem_text}
                            style={{ height: "40px", width: "100%" }}
                          />
                          <Skeleton
                            className={Style.Stem_text}
                            style={{ height: "40px", width: "100%" }}
                          />{" "}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer Box */}
          <div className={`${Style.career_box} row w-100 mx-0`}>
            <div className="col-lg-4  px-0">
              <div className={`${Style.heading}`} data-aos="fade-up">
                {!skeletonOn ? (
                  contentSection4.heading
                ) : (
                  <>
                    {" "}
                    <Skeleton
                      count={3}
                      className={Style.Stem_text}
                      style={{ height: "50px", width: "100%" }}
                    />{" "}
                  </>
                )}
              </div>
            </div>
            <div className={`${Style.ocmt_tex} col-lg-8 px-0`}>
              <div className="w-100" data-aos="fade-up">
                {!skeletonOn ? (
                  contentSection4.paragraphText
                ) : (
                  <>
                    {" "}
                    <Skeleton
                      count={2}
                      className={`${Style.ocmt_tex} col-lg-8 px-0`}
                      style={{ height: "70px", width: "100%" }}
                    />{" "}
                  </>
                )}
              </div>
              <div className="w-100" data-aos="fade-up">
                {!skeletonOn ? (
                  contentSection4.paragraph2
                ) : (
                  <>
                    {" "}
                    <Skeleton
                      count={2}
                      className={`${Style.ocmt_tex} col-lg-8 px-0`}
                      style={{ height: "70px", width: "100%" }}
                    />{" "}
                  </>
                )}
              </div>
            </div>
          </div>
          {/*  Forth Box  our Vission*/}
          <section className={Style.Stem2_program}>
            <div className="row mx-0">
              <div className="col-md-6 order-md-2 order-2 px-0">
                <div className={Style.Stem_program_box}>
                  <div className={Style.Stem_heading} data-aos="fade-up">
                    {!skeletonOn ? (
                      contentSection5.heading
                    ) : (
                      <>
                        {" "}
                        <Skeleton
                          count={1}
                          className={Style.Stem_heading}
                          style={{ height: "50px", width: "50%" }}
                        />{" "}
                      </>
                    )}
                  </div>
                  <div className={Style.content_box}>
                    <div className={Style.Stem_text} data-aos="fade-up">
                      {!skeletonOn ? (
                        contentSection5.paragraphText
                      ) : (
                        <>
                          {" "}
                          <Skeleton
                            count={2}
                            className={Style.Stem_text}
                            style={{ height: "40px", width: "100%" }}
                          />{" "}
                        </>
                      )}
                    </div>
                    <div className={Style.Stem_text} data-aos="fade-up">
                      {!skeletonOn ? (
                        contentSection5.paragraph2
                      ) : (
                        <>
                          {" "}
                          <Skeleton
                            count={2}
                            className={Style.Stem_text}
                            style={{ height: "40px", width: "100%" }}
                          />{" "}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-md-1 order-1 px-0">
                <div className={Style.stemImages_box}>
                  <div className={Style.new_box} data-aos="fade-up">
                    {!skeletonOn ? (
                      <img
                        src={MyService.imageConverter(contentSection5.image)}
                        alt="Corporate Business"
                      />
                    ) : (
                      <>
                        {" "}
                        <Skeleton count={1} className={Style.new_box} />{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default About;
