import React, { useEffect, useState } from "react";
import form from "./Form.module.css";
// import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyService from "../../../MyService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
//  Validation for form
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
console.log("validations", phoneRegExp);
const Schema = Yup.object().shape({
  fname: Yup.string()
    .min(3, "Full Name must be at least 3 characters")
    .required("First Name is required"),
  name: Yup.string()
    .min(3, "Last Name must be at least 3 characters")
    .required("Last Name is required"),
  email: Yup.string()
    .email("Invalid Email format")
    .required("Email is required"),
  address: Yup.string()
    .min(8, "Minimum 8 character is required")
    .required("Address  is required"),
  city: Yup.string()
    .min(3, "Minimum 3 character is required")
    .required("City  is required"),
  
  zipCode: Yup.string().required("Postal/ZIP code is required"),

  reasonOfInterest: Yup.string()
    .min(3, "Minimum 3 character is required")
    .max(200, "Maximum 200 characters is required")
    .required("This field  is required"),
  
  businessExperience: Yup.string().required("This field is required"),
 
  phoneNumber: Yup.string()
    .matches(/^[0-9+]+$/, "Phone number is not valid")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Maximum 15 digits allowed ")
    .required("Phone number is required"),
});
function Form({ open }) {
  const [skeletonOn, setSkeletonOn] = useState(true);
  const [currentRole, setCurrentRole] = useState("");
  const [currError, setCurrError] = useState("");
  // const [currtpuched, setCurrtpuched] = useState(false);
  const [currValid, setCurrValid] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState("1-3 months");
  const [countries, setCountries] = useState([]);
  const [selectEmployee, setSelectEmlopee] = useState("");
  const [selectedCountry, setSelectedCountry] = useState('')
  const [countryValid, setCountryValid] = useState(false)
  const [countryError, setCountryError] = useState('')
  const [stateError, setStateError] = useState('')
  const [stateValid, setStateValid] = useState(false)

const [validEmpCategory,setvalidEmpCategory]=useState(false)
  const [selectedState,setSelectedState]=useState('')
  const [states,setStates]=useState({})
  const [token, setToken] = useState("");
  useEffect(() => {
    const gettingToken = async () => {
      const response = await axios.get(
        "https://ocmt-backend.vercel.app/refresh-token"
      );
      // console.log("Token API Get Response",response.data.response.access_token)
      const updatedToken = response.data.response.access_token;
      // console.log("Updated Token",updatedToken)
      setToken(updatedToken);
      // token=updatedToken;
    };

    gettingToken();
  }, []);

  useEffect(() => {
    let timer;
    if (isSubmit) {
      timer = setTimeout(() => {
        setIsSubmit(false);
        open(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [isSubmit]);

  useEffect(() => {
    const gettingCountry = async () => {
      const response = await MyService.gettingData({}, "Countries");
      console.log("getting Country reponse", response.data.dataItems)
      let data = response.data.dataItems;
      // console.log('county array data', data)
      let updated = []
      const obj = {};
      // let filterizeData = data.forEach(async(element) => {
      //   console.log('Elements', element.data)
      //   let array = element.data;
      //   await setCountries((preElement) => {
      //       return [...preElement,array]
      //     });
      // });
      const updatedItems = data.map(element => {
        const value = element.data;
        const nam = element.data.name;
        const state = element.data.states;
          updated.push(value)
        setStates((pre) => {
          return {
            ...pre,
            [nam]: state
            }
          })
      })
      setCountries([...updated])
      console.log('filterlize country array',countries)
    };
    gettingCountry();
  }, []);
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    isValid,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: {
      email: "",
      text: "",
      name: "",
      fname: "",
      phoneNumber: "",
      address: "",
      city: "",
      state: selectedState,
      zipCode: "",
      country: selectedCountry,
      reasonOfInterest: "",
      commenceTime: selectedExperience,
      businessExperience: "",
      currentRole: currentRole,
      employeeCategory: selectEmployee,
    },
    validationSchema: Schema,
    validateOnMount: true,
    onSubmit: async (values, action) => {
      // console.log("form values formik", values);
      const body = {
        dataCollectionId: "DownloadRequests",
        dataItem: {
          data: {
            firstName: values.fname,
            lastName: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            address: values.address,
            city: values.city,
            state: selectedState,
            country: selectedCountry,
            reasonOfInterest: values.reasonOfInterest,
            commenceTime: selectedExperience,
            businessExperience: values.businessExperience,
            currentRole: currentRole,
            employeeCategory: values.employeeCategory,
            zipCode: values.zipCode,
          },
        },
      };
      try {
        setisLoading(true);
        const response = await axios.post(
          "https://www.wixapis.com/wix-data/v2/items",
          body,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json", // Optional, set the content type based on your API requirements
            },
          }
        );
        // console.log("contact API response", response);
        // console.log("body of API REquest", body);
        if (response.status === 200) {
          setisLoading(false);
          setIsSubmit(true);
          console.log("form reset");
          action.resetForm();
        } else {
          setisLoading(false);
        }
      } catch (errors) {
        setisLoading(false);
        toast.error(" Something went wrong");
        // console.log("catch error", errors);
        action.resetForm();
      }
      action.resetForm();
    },
  });
  console.log('form values',values)
  const handleStates = (e) => {
    const value = e.target.value;
    if (value == '' || !value) {
      setStateValid(false)
      setStateError("Please select a state")
      setSelectedState('')
    } else {
      
      console.log('selected states',value)
      setSelectedState(value)
      setStateValid(true)
      console.log('states object',value)
    }
}
  const handleCountry = (e) => {
    const value = e.target.value;
    console.log('selected country ', value)
    setSelectedState('')
    if (value === "" || !value) {
      setCountryValid(false);
      setSelectedCountry("");
      setCountryError("Country  is required");
      // console.log("validation pass false");
    } else {
      setSelectedCountry(value)
      setCountryValid(true);
      setCountryError("");
    }
    
  }
  const handleCurrentRoleChange = (e) => {
    // setCurrtpuched(true);

    console.log("select Changes", e.target.value);
    const value = e.target.value;
    if (value === "" || !value) {
      setCurrValid(false);
      setvalidEmpCategory(false)
      setCurrentRole("");
      setCurrError("Current Role is required");
      // console.log("validation pass false");
    } else {
      setCurrentRole(value);
      setCurrValid(true);
      // setvalidEmpCategory(true)
      setCurrError("");
      // console.log("validation pass true");
    }
    // console.log("crtRole", currentRole);
    // setCurrentRole(e.target.value);
  };
  console.log('employee categoty',values.employeeCategory)
  return (
    <>
      <section>
        <div className={`${form.container_back}`}>
          <div className={`${form.form_container} row mx-0`}>
            <div className="col-12 px-0">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <div className={form.form_main}>
                    <form onSubmit={handleSubmit} className={` row mx-0 `}>
                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="firstName">First Name:</label>
                          <input
                            type="text"
                            id="firstName"
                            name="fname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.fname}
                          />
                          {errors.fname && touched.fname ? (
                            <p className={form.errors}>{errors.fname}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="lastName">Last Name:</label>
                          <input
                            type="text"
                            id="lastName"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.name}
                          />
                          {errors.name && touched.name ? (
                            <p className={form.errors}>{errors.name}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="email">Email:</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.email}
                          />
                          {errors.email && touched.email ? (
                            <p className={form.errors}>{errors.email}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="phoneNumber">Phone Number:</label>
                          <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength="15"
                            className="form-control"
                            value={values.phoneNumber}
                          />
                          {errors.phoneNumber && touched.phoneNumber ? (
                            <p className={form.errors}>{errors.phoneNumber}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="address">Address:</label>
                          <input
                            type="text"
                            id="address"
                            name="address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.address}
                          />
                          {errors.address && touched.address ? (
                            <p className={form.errors}>{errors.address}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="city">City:</label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.city}
                          />
                          {errors.city && touched.city ? (
                            <p className={form.errors}>{errors.city}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="country">Country:</label>
                          <select
                            id="country"
                            className={` ${form.myCustomSelect} form-select`}
                            // name="country"
                            onChange={handleCountry}
                            placeholder="Select"
                            // onBlur={handleBlur}
                            // value={values.country}
                          >
                            <option value=''>Select</option>
                            {countries?.map((item, i) => {
                              return (
                                <option value={item.name} key={i} >
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {!countryValid && (
                            <p className={form.errors}>{countryError}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="country">Province/State:</label>
                          <select
                            id="country"
                            value={selectedState}
                            className={` ${form.myCustomSelect} form-select`}
                            name="country"
                            onChange={handleStates}
                            placeholder="Select"
                            disabled={!selectedCountry}
                            // onBlur={handleBlur}
                            // value={values.country}
                          >
                            <option value=''>Select</option>
                            {states[selectedCountry]?.map((item, i) => {
                              return (
                                <option value={item} key={i} onClick={()=>console.log('item name',item)}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                          {!stateValid && (
                            <p className={form.errors}>{stateError}</p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="postalCode">Postal/Zip Code:</label>
                          <input
                            type="text"
                            id="postalCode"
                            name="zipCode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.zipCode}
                          />
                          {errors.zipCode && touched.zipCode ? (
                            <p className={form.errors}>{errors.zipCode}</p>
                          ) : null}
                        </div>

                        <div className={form.padding_box}>
                          <label htmlFor="franchiseCommence">
                            Approximately when would you like to commence your
                            franchise?
                          </label>
                          <div className={form.main_radio}>
                            <div className={form.radio_space}>
                              <input
                                type="radio"
                                className={`${form.input_radio} form-check-input`}
                                defaultChecked
                                name="commenceTime"
                                onClick={() =>
                                  setSelectedExperience("1-3 months")
                                }
                                id="commenceTime"
                              />
                              <label
                                class="form-check-label"
                                for="commenceTime"
                              >
                                {" "}
                                <span>1 - 3</span> months
                              </label>
                            </div>
                            <div className={form.radio_space}>
                              <input
                                type="radio"
                                className={`${form.input_radio} form-check-input`}
                                name="commenceTime"
                                onClick={() =>
                                  setSelectedExperience("4-6 months")
                                }
                                id="FourToSix"
                              />
                              <label class="form-check-label" for="FourToSix">
                                {" "}
                                <span>4 - 6</span> months
                              </label>
                            </div>
                            <div className={form.radio_space}>
                              <input
                                type="radio"
                                className={`${form.input_radio} form-check-input`}
                                name="commenceTime"
                                onClick={() =>
                                  setSelectedExperience("7-12 months")
                                }
                                id="SevenToTwelve"
                              />
                              <label
                                class="form-check-label"
                                for="SevenToTwelve"
                              >
                                <span>7 - 12</span> months
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="interestReason">
                            Why are you interested in our franchise?
                          </label>
                          <textarea
                            id="interestReason"
                            name="reasonOfInterest"
                            maxLength="200"
                            className={`${form.input_textarea} form-control`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.reasonOfInterest}
                          />
                          {errors.reasonOfInterest &&
                          touched.reasonOfInterest ? (
                            <p className={form.errors}>
                              {errors.reasonOfInterest}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="currentRole">Current Role:</label>
                          <select
                            id="currentRole"
                            name="currentRole"
                            onChange={handleCurrentRoleChange}
                            className={` ${form.myCustomSelect} form-select`}
                          >
                            <option value="">Select</option>
                            <option value="Employed">Employed</option>
                            <option value="Business Owner">
                              Business Owner
                            </option>
                            <option value="Unemployed">Unemployed</option>
                            <option value="Retired">Retired</option>
                          </select>
                        {!currValid && (
                          <p className={form.errors}>{currError}</p>
                        )}
                        </div>
                      </div>

                      <div className="col-lg-6 order-lg-1 order-2 px-0">
                        <div className={form.padding_box}>
                          <label htmlFor="businessExperience">
                            How many years of business experience do you have?
                          </label>
                          <select
                            id="businessExperience"
                            as="select"
                            className={` ${form.myCustomSelect} form-select`}
                            name="businessExperience"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.businessExperience}
                          >
                            <option value="">Select</option>
                            <option value="0-5 years">0 - 5 years</option>
                            <option value="6-10 years">6 - 10 years</option>
                            <option value="10 years Above">
                              10 years and above
                            </option>
                          </select>
                          {errors.businessExperience &&
                          touched.businessExperience ? (
                            <p className={form.errors}>
                              {errors.businessExperience}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 order-1 order-lg-1 px-0">
                        {currentRole === "Employed" && (
                          <div className={form.padding_box}>
                            <label htmlFor="employedCategory">
                              Employed Category:
                            </label>
                            <select
                              id="employedCategory"
                              className={` ${form.myCustomSelect} form-select`}
                              name="employeeCategory"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.employeeCategory}
                            >
                              <option
                                value=""
                                onClick={() => setSelectEmlopee('')}
                                
                              >
                                Select
                              </option>
                              <option
                                value="C-suites"
                                onClick={() => setSelectEmlopee("C-suites")}
                              >
                                C-suites
                              </option>
                              <option
                                value="Senior Management"
                                onClick={() =>
                                  setSelectEmlopee("Senior Management")
                                }
                              >
                                Senior Management
                              </option>
                              <option
                                value="Middle Management"
                                onClick={() =>
                                  setSelectEmlopee("Middle Management")
                                }
                              >
                                Middle Management
                              </option>
                              <option
                                value="Founder/Owner"
                                onClick={() =>
                                  setSelectEmlopee("Founder/Owner")
                                }
                              >
                                Founder/Owner
                              </option>
                              <option
                                value="Employee"
                                onClick={() => setSelectEmlopee("Employee")}
                              >
                                Employee
                              </option>
                            </select>
                            {errors.employeeCategory &&
                            currentRole === "Employed" &&
                            touched.employeeCategory ? (
                              <p className={form.errors}>
                                {errors.employeeCategory}
                              </p>
                            ) : null}
                          </div>
                        )}
                      </div>

                      {isSubmit && (
                        <div className="row order-3 mx-0 ">
                          <div className={form.message_show}>
                            <div className="col-11 px-0">
                              <div className={form.message_show_text}>
                                Thank you for completing the request for
                                brochure form, a member of our franchise team
                                would get back to you upon reviewing your
                                details.{" "}
                              </div>
                            </div>
                            <div className="col-1 d-flex justify-content-center px-0">
                              <FontAwesomeIcon
                                icon={faClose}
                                className={form.close_icon}
                                onClick={() => setIsSubmit(false)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row order-3  mx-auto">
                        <div className="col-6  ms-auto px-0">
                          <div className={form.padding_box}>
                            <div
                              className={form.btn_main1}
                              onClick={() => open(false)}
                            >
                              <button type="submit" className={form.btn_cancel}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 px-0 ">
                          <div className={form.padding_box}>
                            <div className={form.btn_main2}>
                              <button
                                type="submit"
                                className={
                                  (!(isValid && stateValid && countryValid && currValid) || (currentRole === "Employed" && values.employeeCategory === ""))

                                    ? form.btn_submit_Disable
                                    : form.btn_submit
                                }
                                disabled={!(isValid && stateValid && countryValid && currValid) || (currentRole === "Employed" && values.employeeCategory === "")}
                              >
                                {isLoading ? (
                                  <i
                                    className="fa fa-spin fa-spinner"
                                    aria-hidden="true"
                                    style={{ color: "white" }}
                                  ></i>
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default Form;
