import React, { useEffect } from "react";
import GlobalPcss from "./globalpartnership.module.css";

import DucereBusiness from "../../../assets/Global Partnership/DecereBuseiness.jpg";
import CorporateBusiness from "../../../assets/Global Partnership/CorporateBusiness.jpg";
import TeamDiscussion from "../../../assets/Global Partnership/TeamDiscussion.jpg";
import FortinetSecurity from "../../../assets/Global Partnership/FortinetSecurity.jpg";
import MPOWERFinancing from "../../../assets/Global Partnership/MPower.jpg";
import HolisticInformation from "../../../assets/Global Partnership/holisticInformation.jpg";
import { Link } from "react-router-dom";
import MyService from '../../../MyService';
import Skeleton from 'react-loading-skeleton';
import Aos from "aos";
import "aos/dist/aos.css"
import { useState } from "react";
const GlobalPartnership = () => {
  const [skeletonOn, setSkeletonOn] = useState(true);
  const [sectionOne, setSectionOne] = useState({})
  const [sectionTwo, setSectionTwo] = useState({})
  const [sectionThree, setSectionThree] = useState({})
  const [sectionFour, setSectionFour] = useState({})
  const [sectionFive, setSectionFive] = useState({})
  const [sectionSix, setSectionSix] = useState({})
  const [sectionSeven, setSectionSeven] = useState({})
  const [lists, setLists] = useState([])
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const getting = async () => {

      const response = await MyService.gettingData({ pageId: 4 }, 'OCMTContent')
      console.log('Global content response', response.data.dataItems)
      const responseItem = response.data.dataItems;
      let SplitText;
      let sectionOne = {}
      let sectionTwo = {}
      let sectionThree = {}
      let sectionFour = {}
      let sectionFive = {}
      let sectionSix = {}
      let sectionSeven = {}
      responseItem.forEach(element => {
        if (element.data.sectionId === 1) {
          sectionOne = element.data
          setSectionOne(element.data)
          setSkeletonOn(false);
          // setSecOne(element.data)
        } else if (element.data.sectionId === 2) {
          sectionTwo = element.data
          setSectionTwo(element.data)
          setSkeletonOn(false);
          // setSecTwo(element.data)
        } else if (element.data.sectionId === 3) {
          sectionThree = element.data
          setSectionThree(element.data)
          setSkeletonOn(false);
          // setSecThree(element.data)
        } else if (element.data.sectionId === 4) {
          sectionFour = element.data
          setSectionFour(element.data)
          setSkeletonOn(false);
          // setSecFour(element.data))
          // setFourArray(seprate)
        } else if (element.data.sectionId === 5) {
          sectionFive = element.data
          setSectionFive(element.data)
          setSkeletonOn(false);
        } else if (element.data.sectionId === 6) {
          sectionSix = element.data
          let lstArray;
          let lst = element.data.checkList;
          lstArray = lst.split(",")
          console.log("List Items", lstArray)
          setLists(lstArray)
          setSectionSix(element.data)
          setSkeletonOn(false);
        } else if (element.data.sectionId === 7) {
          sectionSeven = element.data
          setSectionSeven(element.data)
          setSkeletonOn(false);
        }
        console.log("section One", sectionOne)
        console.log("section Two", sectionTwo)
        console.log("section Three", sectionThree)
        console.log("section Four", sectionFour)
        console.log("section Five", sectionFive)
        console.log("section six", sectionSix)
        console.log("section six points", sectionSix.points)
        console.log("section seven", sectionSeven)
      });
    }
    getting();
  }, [])
  return (

    <>
      <div className="container-fluid px-0">
      
      {!skeletonOn ? 
        <section className={GlobalPcss.opportunity_blocks}>
          <div className={GlobalPcss.blocks_heading} data-aos="fade-up">
            { sectionOne.heading}

          </div>
          <div className={GlobalPcss.paragraphs_text} data-aos="fade-up">
            { sectionOne.paragraphText}


          </div>
          <div className={GlobalPcss.paragraphs_text} data-aos="fade-up">
            { sectionOne.paragraph2}

          </div>
        </section>:
         <Skeleton count={6} className={GlobalPcss.Stem_heading} style={{ height: '20px', width: '100%', marginTop:'20px' }} />}

        
        <section className={GlobalPcss.Stem2_program}>
          <div className="row mx-0">
            <div className="col-md-6 order-md-1 order-2 px-0">
              <div className={GlobalPcss.Stem_program_box}>

                <div className={GlobalPcss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? sectionTwo.heading : <Skeleton className={GlobalPcss.Stem_heading} style={{ height: '55px', width: '70%', }} />}

                </div>
                <div className={GlobalPcss.content_box}>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionTwo.paragraphText : <Skeleton count={6} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                  </div>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionTwo.paragraph2 : <Skeleton count={2} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-1  px-0">
              <div className={GlobalPcss.stemImages_box}>
                <div className={GlobalPcss.new_box} data-aos="fade-up">
                  {!skeletonOn ? <img src={MyService.imageConverter(sectionTwo.image)} alt="Ducere Business" /> : <Skeleton className={GlobalPcss.img_row} style={{ height: '389px', width: '100%', }} />}

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={GlobalPcss.Stem2_program}>
          <div className="row mx-0">
            <div className="col-md-6 order-md-1 order-1 px-0">
              <div className={GlobalPcss.stemImages_box}>
                <div className={GlobalPcss.new_box} data-aos="fade-up">
                  {!skeletonOn ? <img src={MyService.imageConverter(sectionThree.image)} alt="Corporate Business" /> : <Skeleton className={GlobalPcss.img_row} style={{ height: '430px', width: '100%', }} />}

                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-2 px-0">
              <div className={GlobalPcss.Stem_program_box}>
                <div className={GlobalPcss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? sectionThree.heading : <Skeleton className={GlobalPcss.Stem_heading} style={{ height: '55px', width: '70%', }} />}



                </div>
                <div className={GlobalPcss.content_box}>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionThree.paragraphText : <Skeleton count={4} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                  </div>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionTwo.paragraph2 : <Skeleton count={6} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={GlobalPcss.Stem2_program}>
          <div className="row mx-0">
            <div className="col-md-6 order-md-1 order-2 px-0">
              <div className={GlobalPcss.Stem_program_box}>
                <div className={GlobalPcss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? sectionFour.heading : <Skeleton className={GlobalPcss.Stem_heading} style={{ height: '55px', width: '70%', }} />}


                </div>
                <div className={GlobalPcss.content_box}>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionFour.paragraphText : <Skeleton count={4} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                  </div>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionFour.paragraph2 : <Skeleton count={3} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}


                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-1 px-0">
              <div className={GlobalPcss.stemImages_box}>
                <div className={GlobalPcss.new_box} data-aos="fade-up">
                  {!skeletonOn ? <img src={MyService.imageConverter(sectionFour.image)} alt="Palo Alto Networks Inc." /> : <Skeleton className={GlobalPcss.img_row} style={{ height: '370px', width: '100%', }} />}

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={GlobalPcss.Stem2_program}>
          <div className="row mx-0">
            <div className="col-md-6 order-md-1 order-1 px-0">
              <div className={GlobalPcss.stemImages_box}>
                <div className={GlobalPcss.new_box} data-aos="fade-up">
                  {!skeletonOn ? <img
                    src={MyService.imageConverter(sectionFive.image)}
                    alt="Fortinet Global Cybersecurity"
                  /> : <Skeleton className={GlobalPcss.img_row} style={{ height: '370px', width: '100%', }} />}


                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-2 px-0">
              <div className={GlobalPcss.Stem_program_box}>
                <div className={GlobalPcss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? sectionFive.heading : <Skeleton className={GlobalPcss.Stem_heading} style={{ height: '55px', width: '70%', }} />}


                </div>
                <div className={GlobalPcss.content_box}>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionFive.paragraphText : <Skeleton count={3} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}


                  </div>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionFive.paragraph2 : <Skeleton count={4} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={GlobalPcss.Stem2_program}>
          <div className="row mx-0">
            <div className="col-md-6 order-md-1 order-2 px-0">
              <div className={GlobalPcss.Stem_program_box}>
                <div className={GlobalPcss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? sectionSix.heading : <Skeleton className={GlobalPcss.Stem_heading} style={{ height: '55px', width: '70%', }} />}

                </div>
                <div className={GlobalPcss.content_box}>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionSix.paragraphText : <Skeleton count={3} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                  </div>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionSix.paragraph2 : <Skeleton count={2} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                    <div className={GlobalPcss.Stem_text1} data-aos="fade-up">
                      {!skeletonOn ? sectionSix.paragraph3 : <Skeleton className={GlobalPcss.Stem_text1} style={{ height: '20px', width: '100%', }} />}

                    </div>
                    <div className={GlobalPcss.Stem_text1} data-aos="fade-up" dangerouslySetInnerHTML={{ __html: sectionSix.points }} ></div>
                    {/* <ul>
                      {lists.length>0 && lists.map((item,index)=>{
                        return(
                          <li className={GlobalPcss.Stem_text1} data-aos="fade-up" key={index}>
                            {!skeletonOn?item:<Skeleton count={1}/>}
                          </li>
                        )
                      })}
                  
                    
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-1 px-0">
              <div className={GlobalPcss.stemImages_box}>
                <div className={GlobalPcss.new_box} data-aos="fade-up">
                  {!skeletonOn ? <img
                    src={MyService.imageConverter(sectionSix.image)}
                    alt="MPOWER Financing"
                  /> : <Skeleton className={GlobalPcss.img_row} style={{ height: '370px', width: '100%', }} />}

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={GlobalPcss.Stem2_program}>
          <div className="row mx-0">
            <div className="col-md-6 order-md-1 order-1 px-0">
              <div className={GlobalPcss.stemImages_box}>
                <div className={GlobalPcss.new_box} data-aos="fade-up">
                  {!skeletonOn ? <img
                    src={MyService.imageConverter(sectionSeven.image)}
                    alt="Holistic Information Security Practitioner Institute (HISPI)"
                  /> : <Skeleton className={GlobalPcss.img_row} style={{ height: '458px', width: '100%', }} />}


                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 order-2 px-0">
              <div className={GlobalPcss.Stem_program_box}>

                <div className={GlobalPcss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? sectionSeven.heading : <Skeleton count={2} className={GlobalPcss.Stem_heading} style={{ height: '55px', width: '70%', marginBottom: '20px' }} />}


                </div>
                <div className={GlobalPcss.content_box}>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionSeven.paragraphText : <Skeleton count={3} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                  </div>
                  <div className={GlobalPcss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? sectionSeven.paragraph2 : <Skeleton count={5} className={GlobalPcss.Stem_text} style={{ height: '20px', width: '100%', }} />}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GlobalPartnership;
