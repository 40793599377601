import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import FooterCss from "./footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Footer = ({ footerData, footText, skeletonOn }) => {
  const location = useLocation();
  return (
    <footer className={`${FooterCss.mfooter} row mx-0 `}>
      <div className="col-12 px-0">
        <ul className={FooterCss.header_items}>
          {footerData.map((item, index) => {
            const { text, urls } = item;
            return (
              <li className={`${FooterCss.header_item} `} key={index}>
                <NavLink
                  className={
                    location.pathname === urls
                      ? FooterCss.active
                      : FooterCss.header_link
                  }
                  to={urls}
                >
                  {skeletonOn ? (
                    text
                  ) : (
                    <Skeleton
                      count={1}
                      className={
                        location.pathname === urls
                          ? FooterCss.active
                          : FooterCss.header_link
                      }
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                    />
                  )}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="col-12 px-0">
        {skeletonOn ? (
          <div className={FooterCss.Socail_icons}>
            <a href="https://www.ocmtontario.ca" target="_blank" rel="noreferrer">
            <div className={FooterCss.Socail_icon}>
              <FontAwesomeIcon icon={faGlobe} />
              </div>
              </a>
            <a href="https://www.facebook.com/Ocmt.canada" target="_blank" rel="noreferrer">
              <div className={FooterCss.Socail_icon}>
                <FontAwesomeIcon icon={faFacebookF} />
              </div>
            </a>
            <a href="https://www.instagram.com/ocmt.canada" target="_blank" rel="noreferrer">
              <div className={FooterCss.Socail_icon}>
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </a>
            <a href="https://www.youtube.com/OCMTChannel" target="_blank" rel="noreferrer">
              <div className={FooterCss.Socail_icon}>
                <FontAwesomeIcon icon={faYoutube} />
              </div>
            </a>
            <a href="https://twitter.com/CanadaOcmt" target="_blank" rel="noreferrer">
              <div className={FooterCss.Socail_icon}>
                <FontAwesomeIcon icon={faXTwitter} />
              </div>
            </a>
          </div>
        ) : (
          <Skeleton
            count={1}
            className={FooterCss.Socail_icons}
            style={{
              height: "60px",
              width: "40%",
              margin: "0 auto",
            }}
          />
        )}
      </div>
      <div className="col-12 px-0">
        <div className={FooterCss.stem_second_text}>
          {skeletonOn ? (
            footText
          ) : (
            <Skeleton
              count={1}
              className={FooterCss.stem_second_text}
              style={{
                height: "50px",
                width: "60%",
              }}
            />
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
