import React, { useEffect } from "react";
import Homecss from "./home.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";
import MyService from "../../../MyService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import Form from "../Form/Form";
const Home = () => {
  const [secOne, setSecOne] = useState({});
  const [setTwo, setSetTwo] = useState({});
  const [secThree, setSecThree] = useState({});
  const [secFour, setSecFour] = useState({});
  const [secFive, setSecFive] = useState({});
  const [secSix, setSecSix] = useState({});
  const [skeletonOn, setSkeletonOn] = useState(true);
  const [convertedImage, setConvertedImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modeldata, setModeldata] = useState("");
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  useEffect(() => {
    const getting = async () => {
      const response = await MyService.gettingData(
        { pageId: 1 },
        "OCMTContent"
      );
      console.log("Home content response", response.data.dataItems);
      const responseItem = response.data.dataItems;
      let sectionOne = {};
      let sectionTwo = {};
      let sectionThree = {};
      let sectionFour = {};
      let sectionFive = {};
      let sectionSix = {};
      responseItem.forEach((element) => {
        if (element.data.sectionId === 1) {
          sectionOne = element.data;
          setSecOne(element.data);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 2) {
          sectionTwo = element.data;
          setSetTwo(element.data);

          setSkeletonOn(false);
        } else if (element.data.sectionId === 3) {
          sectionThree = element.data;
          setSecThree(element.data);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 4) {
          sectionFour = element.data;
          setSecFour(element.data);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 5) {
          sectionFive = element.data;
          setSecFive(element.data);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 6) {
          sectionSix = element.data;
          setSecSix(element.data);
          setSkeletonOn(false);
        }
      });
    };
    getting();
  }, []);

  console.log("home states 1 content", secOne);
  console.log("home states 2 content", setTwo);
  console.log("home states 3 content", secThree);
  console.log("home states 4 content", secFour);
  console.log("home states 5 content", secFive);
  console.log("home states 6 content", secSix);

  const [showModal, setShowModal] = useState(false);

  const handleModel = (value) => {
    if (value === "one") {
      setModeldata(secThree.readMore);
    } else if (value === "two") {
      setModeldata(secFour.readMore);
    } else if (value === "three") {
      setModeldata(secFive.readMore);
    }
    setShowModal(true);
    console.log("Model", value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {isOpen &&
        createPortal(
          <div className={Homecss.portal}>
            <Form open={setIsOpen} />
          </div>,
          document.getElementById("portal")
        )}
      {showModal &&
        createPortal(
          <div className={Homecss.model_overlay}>
            <div class={Homecss.model_style}>
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title fs-5"
                      id="staticBackdropLabel"
                    ></h1>

                    <button
                      type="button"
                      className="btn-close m-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal}
                    ></button>
                  </div>

                  <div
                    className={`modal-body  ${Homecss.model_body}`}
                    dangerouslySetInnerHTML={{ __html: modeldata }}
                  ></div>
                  <hr />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn  mx-2 py-2 px-5"
                      style={{ backgroundColor: "#8b0000", color: "white" }}
                      data-bs-dismiss="modal"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>,

          document.getElementById("portal")
        )}
      <div className="container-fluid px-0">
        <div className={Homecss.home_container}>
          <section className={Homecss.section_home}>
            {!skeletonOn ? (
              <div className={Homecss.image_poster}>
                <img
                  src={MyService.imageConverter(secOne.image)}
                  srcset={MyService.imageConverter(secOne.image)}
                  alt="banner "
                />
              </div>
            ) : (
              <Skeleton count={1} className={Homecss.image_poster} />
            )}

            <div className={Homecss.banner_paster}>
              <motion.div
                className={Homecss.heading_home}
                initial={{ x: -1000 }}
                animate={{ x: 0 }}
                transition={{
                  duration: 1,
                }}
              >
                {!skeletonOn ? (
                  secOne.heading
                ) : (
                  <Skeleton
                    className={Homecss.heading_home}
                    style={{
                      height: "60px",
                      width: "80%",
                      marginTop: "-100px",
                      marginLeft: "0px",
                    }}
                  />
                )}
              </motion.div>
              <motion.div
                className={Homecss.text_home}
                initial={{ x: -1000 }}
                animate={{ x: 0 }}
                transition={{
                  duration: 1,
                }}
              >
                {!skeletonOn ? (
                  secOne.paragraphText
                ) : (
                  <Skeleton
                    className={Homecss.heading_home}
                    style={{
                      height: "50px",
                      width: "60%",
                      marginTop: "0px",
                      marginLeft: "0px",
                    }}
                  />
                )}
              </motion.div>
              {!skeletonOn ? (
                <motion.button
                  className={Homecss.button_home}
                  initial={{ x: -1000 }}
                  animate={{ x: 0 }}
                  transition={{
                    duration: 0.5,
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  {secOne.button}
                </motion.button>
              ) : (
                <Skeleton
                  className={Homecss.heading_home}
                  style={{
                    height: "60px",
                    width: "30%",
                    marginTop: "100px",
                    marginLeft: "75px",
                  }}
                />
              )}
            </div>
          </section>
          <section className={Homecss.opportunity_blocks}>
            <div className={Homecss.blocks_heading} data-aos="fade-up">
              {!skeletonOn ? (
                setTwo.heading
              ) : (
                <Skeleton
                  className={Homecss.blocks_heading}
                  style={{
                    height: "60px",
                    width: "50%",
                  }}
                />
              )}
            </div>
            <div className={Homecss.paragraphs_text} data-aos="fade-up">
              {!skeletonOn ? (
                setTwo.paragraphText
              ) : (
                <Skeleton
                  count={2}
                  className={Homecss.paragraphs_text}
                  style={{
                    height: "40px",
                    width: "100%",
                  }}
                />
              )}
            </div>
            <div className={Homecss.paragraphs_text} data-aos="fade-up">
              {!skeletonOn ? (
                setTwo.paragraph2
              ) : (
                <Skeleton
                  count={2}
                  className={Homecss.paragraphs_text}
                  style={{
                    height: "40px",
                    width: "100%",
                  }}
                />
              )}
            </div>
            <div className={Homecss.paragraphs_text} data-aos="fade-up">
              {!skeletonOn ? (
                setTwo.paragraph3
              ) : (
                <Skeleton
                  count={2}
                  className={Homecss.paragraphs_text}
                  style={{
                    height: "40px",
                    width: "100%",
                  }}
                />
              )}
            </div>

            {!skeletonOn ? (
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <button className={Homecss.button1_home} data-aos="fade-up">
                  {setTwo.button}
                </button>
              </Link>
            ) : (
              <Skeleton
                count={1}
                className={Homecss.paragraphs_text}
                style={{
                  height: "50px",
                  width: "25%",
                  margin: "50px auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          </section>
          <section className={Homecss.Stem_program}>
            <div className="row mx-0">
              <div
                className="col-md-6 order-md-1 order-2 px-0"
                // data-aos="fade-up"
              >
                <div className={Homecss.Stem_program_box}>
                  <div className={Homecss.Stem_heading} data-aos="fade-up">
                    {!skeletonOn ? (
                      secThree.heading
                    ) : (
                      <Skeleton
                        className={Homecss.blocks_heading}
                        style={{
                          height: "60px",
                          width: "50%",
                        }}
                      />
                    )}
                  </div>
                  <div className={Homecss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? (
                      secThree.paragraphText
                    ) : (
                      <Skeleton
                        count={2}
                        className={Homecss.paragraphs_text}
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                      />
                    )}
                  </div>

                  {!skeletonOn ? (
                    <div
                      className={Homecss.Stem_button}
                      data-aos="fade-up"
                      onClick={() => handleModel("one")}
                    >
                      {secThree.button}
                    </div>
                  ) : (
                    <Skeleton
                      count={1}
                      className={Homecss.paragraphs_text}
                      style={{
                        height: "50px",
                        width: "35%",
                        margin: "50px auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="col-md-6 order-md-2 order-1 px-0"
                // data-aos="fade-up"
              >
                <div className={Homecss.stemImages_box} data-aos="fade-up">
                  {!skeletonOn ? (
                    <img
                      src={MyService.imageConverter(secThree.image)}
                      alt="Ducere Business"
                    />
                  ) : (
                    <Skeleton
                      className={Homecss.stemImages_box}
                      style={{ margin: "10px", height: "96%", width: "96%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className={Homecss.Stem1_program}>
            <div className="row mx-0">
              <div className="col-md-6 order-md-1 order-1 px-0">
                <div className={Homecss.stemImages_box} data-aos="fade-up">
                  {!skeletonOn ? (
                    <img
                      src={MyService.imageConverter(secFour.image)}
                      alt="Ducere Business"
                    />
                  ) : (
                    <Skeleton
                      className={Homecss.stemImages_box}
                      style={{ margin: "10px", height: "96%", width: "96%" }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 order-md-2 order-2 px-0">
                <div className={Homecss.Stem_program_box}>
                  <div className={Homecss.Stem_heading} data-aos="fade-up">
                    {!skeletonOn ? (
                      secFour.heading
                    ) : (
                      <Skeleton
                        className={Homecss.blocks_heading}
                        style={{
                          height: "60px",
                          width: "50%",
                        }}
                      />
                    )}
                  </div>
                  <div className={Homecss.Stem_text} data-aos="fade-up">
                    {!skeletonOn ? (
                      secFour.paragraphText
                    ) : (
                      <Skeleton
                        count={2}
                        className={Homecss.paragraphs_text}
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                      />
                    )}
                  </div>

                  {!skeletonOn ? (
                    <div
                      className={Homecss.Stem_button}
                      data-aos="fade-up"
                      onClick={() => handleModel("two")}
                    >
                      {secFour.button}
                    </div>
                  ) : (
                    <Skeleton
                      count={1}
                      className={Homecss.paragraphs_text}
                      style={{
                        height: "50px",
                        width: "35%",
                        margin: "50px auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className={Homecss.Stem2_program}>
            <div className="row mx-0">
              <div className="col-md-6 order-md-1 order-2 px-0">
                <div className={Homecss.Stem_program_box}>
                  <div className={Homecss.Stem_heading} data-aos="fade-up">
                    {!skeletonOn ? (
                      secFive.heading
                    ) : (
                      <>
                        <Skeleton
                          className={Homecss.blocks_heading}
                          style={{
                            height: "60px",
                            width: "50%",
                          }}
                        />
                        <Skeleton
                          count={2}
                          className={Homecss.paragraphs_text}
                          style={{
                            height: "50px",
                            width: "70%",
                          }}
                        />
                      </>
                    )}
                  </div>

                  {!skeletonOn ? (
                    <div
                      className={Homecss.Stem_button}
                      data-aos="fade-up"
                      onClick={() => handleModel("three")}
                    >
                      {secFive.button}
                    </div>
                  ) : (
                    <Skeleton
                      count={1}
                      className={Homecss.paragraphs_text}
                      style={{
                        height: "50px",
                        width: "35%",
                        margin: "50px auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 order-md-2 order-1 px-0">
                <div className={Homecss.stemImages_box} data-aos="fade-up">
                  {!skeletonOn ? (
                    <img
                      src={MyService.imageConverter(secFive.image)}
                      alt="Ducere Business"
                    />
                  ) : (
                    <Skeleton
                      className={Homecss.stemImages_box}
                      style={{ margin: "10px", height: "96%", width: "96%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className={Homecss.Stem3_program}>
            <div className="row mx-0">
              <div className="col-md-6 order-md-1 order-1 px-0">
                <div className={Homecss.Stem_program3_box}>
                  <div className={Homecss.Stem_heading3} data-aos="fade-up">
                    {!skeletonOn ? (
                      secSix.heading2
                    ) : (
                      <Skeleton
                        count={2}
                        className={Homecss.blocks_heading}
                        style={{
                          height: "60px",
                          width: "90%",
                        }}
                      />
                    )}
                  </div>

                  {!skeletonOn ? (
                    <div className={Homecss.Stem_Sparrow} data-aos="fade-up">
                      <img
                        src={MyService.imageConverter(secSix.image)}
                        alt=""
                      />
                    </div>
                  ) : (
                    <Skeleton
                      count={1}
                      className={Homecss.paragraphs_text}
                      style={{
                        height: "50px",
                        width: "35%",
                        margin: "50px auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 order-md-2 order-2 px-0">
                <div className={Homecss.Stem_program_box}>
                  <div className={Homecss.Stem1_heading} data-aos="fade-up">
                    {!skeletonOn ? (
                      secSix.heading
                    ) : (
                      <Skeleton
                        className={Homecss.blocks_heading}
                        style={{
                          height: "60px",
                          width: "50%",
                          marginBottom: "50px",
                        }}
                      />
                    )}
                  </div>

                  {!skeletonOn ? (
                    <div className={Homecss.Socail_icons}>
                      <a
                        href="https://www.ocmtontario.ca"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={Homecss.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faGlobe} size="2x" />
                        </div>
                      </a>
                      <a
                        href="https://www.facebook.com/Ocmt.canada"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={Homecss.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faFacebookF} size="2x" />
                        </div>
                      </a>
                      <a
                        href="https://www.instagram.com/ocmt.canada"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={Homecss.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </div>
                      </a>
                      <a
                        href="https://www.youtube.com/OCMTChannel"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={Homecss.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faYoutube} size="2x" />
                        </div>
                      </a>
                      <a
                        href="https://twitter.com/CanadaOcmt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={Homecss.Socail_icon} data-aos="fade-up">
                          <FontAwesomeIcon icon={faXTwitter} size="2x" />
                        </div>
                      </a>
                    </div>
                  ) : (
                    <Skeleton
                      count={2}
                      className={Homecss.paragraphs_text}
                      style={{
                        height: "50px",
                        width: "70%",

                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  )}

                  {!skeletonOn ? (
                      <a href="mailto:franchise@ocmtontario.ca" style={{textDecoration:'none'}}>
                    <div className={Homecss.Stem_email_box}>
                        <div className={Homecss.stem_email} data-aos="fade-up">
                          <FontAwesomeIcon icon={faEnvelope} />
                          <div className={Homecss.stem_phone_text}>
                            franchise@ocmtontario.ca
                          </div>
                        </div>
                    </div>
                      </a>
                  ) : (
                    <Skeleton
                      count={1}
                      className={Homecss.paragraphs_text}
                      style={{
                        height: "50px",
                        width: "35%",
                        margin: "50px auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Home;
