import React, { useState, useEffect } from "react";
import Header from "./Components/Shared/Header/Header";
import Footer from "./Components/Shared/Footer/Footer";
import { Outlet } from "react-router-dom";
import MyService from "./MyService";
const RootLayout = () => {
  const [header, setHeader] = useState([]);
  const [footer, setFooter] = useState([]);
  const [footerIcon, setFooterIcon] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [footerText, setfooterText] = useState("");
  const [skeletonOn, setSkeletonOn] = useState(false);

  useEffect(() => {
    const gettingToken = async () => {
      const resp = await MyService.gettingData({}, "Menu");
      console.log("heading response", resp.data.dataItems);
      const dataItems = resp.data.dataItems;
      let filterData = [];
      let filterFooterData = [];
      let filterIcon = [];
      if (resp.status === 200) {
        setSkeletonOn(true);
      }
      dataItems.forEach((element) => {
        if (element.data.type === "headerLink") {
          filterData.push(element.data);
        } else if (element.data.type === "footerLink") {
          filterFooterData.push(element.data);
        } else if (element.data.type === "footerIcon") {
          filterIcon.push(element.data);
        } else if (element.data.type === "headerText") {
          setHeaderText(element.data.text);
        } else if (element.data.type === "footerText") {
          setfooterText(element.data.text);
        }
      });
      filterData.sort((a, b) => a.order - b.order);
      filterFooterData.sort((a, b) => a.order - b.order);
      filterIcon.sort((a, b) => a.order - b.order);
      console.log("Filterlize Header Data ", filterData);
      console.log("Filterlize Footer Data ", filterFooterData);
      console.log("Filterlize Footer Icon", filterIcon);
      setHeader(filterData);
      setFooter(filterFooterData);
      setFooterIcon(filterIcon);
    };

    gettingToken();
  }, []);
  return (
    <>
      <Header
        headerData={header}
        headText={headerText}
        skeletonOn={skeletonOn}
      />
      <section>
        <Outlet />
      </section>
      <Footer
        footerData={footer}
        footerIcon={footerIcon}
        footText={footerText}
        skeletonOn={skeletonOn}
      />
    </>
  );
};

export default RootLayout;
