import React, { useEffect,useState } from "react";
import opportunities from "./opportunities.module.css";
import Click from "../../../assets/Opertunities/click.png";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import 'aos/dist/aos.css'
import MyService from '../../../MyService';
import { createPortal } from "react-dom";
import Form from '../Form/Form';
const Opportunities = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [secOne,setSecOne]=useState({})
  const [secTwo,setSecTwo]=useState({})
  const [secThree,setSecThree]=useState({})
  const [secFour,setSecFour]=useState({})
  const [secFive,setSecFive]=useState({})
  const [tickImages,setTickImages]=useState('')
  const [sideBanner,setSideBanner]=useState('')
  const [secFourArray,setFourArray]=useState([])
  const [cards,setCards]=useState([])
  const [skeletonOn,setSkeletonOn]=useState(true)
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  useEffect(()=>{
    const getting=async()=>{

      const response= await MyService.gettingData({pageId:3},'OCMTContent')
      if(response.status ===200){
        setSkeletonOn(false)
      }
      // console.log('Opportunity content response',response.data.dataItems)
      const responseItem=response.data.dataItems;
      let sectionOne={}
      let sectionTwo={}
      let sectionThree={}
      let sectionFour={}
      let sectionFive={}
      let SplitText;
      responseItem.forEach(element => {
        if(element.data.sectionId===1){
            sectionOne=element.data
            setSecOne(element.data)
        }else if(element.data.sectionId===2){
          sectionTwo=element.data
          setSecTwo(element.data)
        }else if(element.data.sectionId===3){
          sectionThree=element.data
          setSecThree(element.data)
        }else if(element.data.sectionId===4){
          sectionFour=element.data
          setSecFour(element.data)
          let Images=element.data.checkListImages;
          console.log('checks imges backend',Images)
         let checks= Images.split(",")
         console.log('Checks Images array',checks)
         setTickImages(checks[0])
         setSideBanner(checks[1])
          SplitText=element.data.checkList;
          console.log("tick iamges",checks[0])
          console.log("side Banner Images",checks[1])
          let seprate=SplitText.split(',')
          setFourArray(seprate)
        }else if(element.data.sectionId===5){
          sectionFive=element.data
          setSecFive(element.data)
        }
      });
    }
    getting();
  },[])

  useEffect(()=>{
    const gettingCards=async()=>{
      const response= await MyService.gettingData({pageNumber:3},'CardsGallery')
      console.log('cards response',response.data.dataItems)
      const Data=response.data.dataItems;
      let cardsData=[]
      Data.forEach(element => {
        cardsData.push(element.data)
      })
      cardsData.sort((a,b)=>a.order-b.order)
      setCards(cardsData)
    }
    gettingCards();
  },[])
  console.log('Opportunity states 1 content',secFour)
  // console.log('Opportunity tick content',tickImages)
  // console.log('Opportunity sideBanner content',sideBanner)
  console.log('cards',cards)
  // console.log('sideBaner Images Link',sideBanner)
  return (
    <>
    {
        isOpen && createPortal(
        <div className={opportunities.portal}>
        <Form open={setIsOpen}/>
        </div>
        ,document.getElementById("portal")
      )
    
    }
      <section>
        <div>
        {skeletonOn ? <Skeleton  count={1}
                  className={opportunities.img_main}
                  // style={{ height:"100%" , width: "100%" }}
                  />
        : <img src={MyService.imageConverter(secOne.image)} alt="" className={opportunities.img_main}/>
         }
        </div>
        <div className={`${opportunities.container_main} container-fluid px-0`}>
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <div className={opportunities.opportunities_text} data-aos="fade-up">
              {skeletonOn?<Skeleton
                count={1}
                className={`${opportunities.oppor_heading} `}
                />:<h1 className={`${opportunities.oppor_heading} `}>
                {secTwo.heading}
                </h1>}
                
                <span className={`${opportunities.oppor_span} `}>
                {skeletonOn?<Skeleton
                  count={2}
                  />:secTwo.paragraphText}
                </span>
              </div>
            </div>
          </div>

          <div className={`${opportunities.career_box} row w-100 mx-0`}>
            <div className="col-lg-5  px-0" data-aos="fade-up">
              <div className={`${opportunities.heading}`}>
                <div> {skeletonOn?<Skeleton count={2}/>:secThree.heading}</div>
              </div>
            </div>
            <div className={`${opportunities.ocmt_tex} col-lg-7 px-0`} data-aos="fade-up">
              <div style={{width:"80%"}}>
              {skeletonOn ?  <Skeleton count={4} style={{ height: '20px', width: '100%'}} />:secThree.paragraphText}

              </div>
              <div style={{width:"80%"}}>
              {skeletonOn?<Skeleton count={3} />:secThree.paragraph2}
              </div>
            </div>
          </div>
          <section className={opportunities.Store_container}>
            <div className={opportunities.store_boxes}>
            {cards.length>0 && cards.map((item,index)=>{
              const {image,text}=item;
              return(

                <div className={opportunities.store_box_item} data-aos="fade-up" key={index}>
                  <div className={opportunities.store_box_image}>
                  {skeletonOn?<Skeleton className={opportunities.store_box_item} count={9} style={{height:"100%",width:"100%"}}/>:<img src={MyService.imageConverter(image)} alt="Team Meeting" />}
                    
                  </div>
                  <div className={opportunities.store_box_title}>
                    <div className={opportunities.store_boxtext}>
                      {skeletonOn?<Skeleton count={1} className={opportunities.store_boxtext} style={{height:"20px",width:"70%"}}/>:text}
                    </div>
                  </div>
                </div>
              )
            })}
             
            </div>
          </section>

          <div className="row mt-5 mx-0">
            <div className="col-lg-6 px-0" >
            {skeletonOn?<Skeleton className={opportunities.img_oppor} count={1}/>:<img src={MyService.imageConverter(secFour.image)} alt="side Banner" className={opportunities.img_oppor} />}
              
            </div>
            <div className="col-lg-6 px-0" >
              <div className={opportunities.click_details}>
              {secFourArray.length>0 && secFourArray.map((item,index)=>{
                return(
                  <div className={opportunities.click_main} data-aos="fade-up" key={index}>
                  {skeletonOn?<Skeleton className={opportunities.click_img} count={1}/>: <img src={MyService.imageConverter(Click)} alt="" className={opportunities.click_img} />}
                   
                    <span className={opportunities.click_span} >
                      {skeletonOn?<Skeleton count={1} className={opportunities.click_span} style={{height:"20px",width:"70%"}}/>:item}
                    </span>
                  </div>
                )
              })}
              </div>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-md-12 overflow-hidden px-0">
              <div className={opportunities.parent_box_full}>
                <div className={opportunities.bottom_box}>
                  <div className={`${opportunities.download_details}`}>
                  {skeletonOn?<Skeleton count={1} className={opportunities.brochure} />: <div className={opportunities.brochure} data-aos="fade-up">
                  {secFive.heading}
                 </div>}
                   
                    <div className={opportunities.brochure_text} data-aos="fade-up">
                      {skeletonOn?<Skeleton count={1} style={{height:"30px",width:"30%"}}/>:secFive.paragraphText}
                    </div>
                    <div className={opportunities.brochure_text}>
                     {skeletonOn?<Skeleton count={1} className={opportunities.brochure_button} />: <button className={opportunities.brochure_button} data-aos="fade-up" onClick={()=>setIsOpen(true)}>
                     {secFive.button}
                      </button>
                }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Opportunities;
