import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

const App = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App
