import axios from "axios";

 class Service {
  gettingData = async (filter, dataCollectionId) => {
    const response = await axios.get(
      "https://ocmt-backend.vercel.app/refresh-token"
    );
    const updatedToken = response.data.response.access_token;
    if (response.status === 200) {
      return await axios.post(
        "https://www.wixapis.com/wix-data/v2/items/query",
        {
          dataCollectionId: dataCollectionId,
          query: {
            filter: filter,
            paging: {
              "limit": 250
            },
          },
        },
        {
          headers: {
            Authorization: ` ${updatedToken}`,
            "Content-Type": "application/json", // Optional, set the content type based on your API requirements
          },
        }
      );
    }
  };
  imageConverter=(imageSRC)=>{
    
      
    //convert the wix:image url to something that can be displayed inside html-component
    if(imageSRC){

    
    let strReturnImage = "";
      if (imageSRC.startsWith("wix:image:")) {
      let wixImageURL = "";
             wixImageURL = "https://static.wixstatic.com/media/";
      let wixLocalURL = "";
             wixLocalURL = imageSRC.replace('wix:image://v1/', '');
             wixLocalURL = wixLocalURL.substr(0, wixLocalURL.lastIndexOf('/'));
             strReturnImage = wixImageURL + wixLocalURL;
         } else {
             strReturnImage = imageSRC;
         }
      return strReturnImage;
        }else{
          return ""
        }
  }
}
const ServiceMethod=new Service();
export default ServiceMethod;