import React, { useEffect, useState } from "react";
import Franchisecss from "./franchisee.module.css";
import { Link } from "react-router-dom";
import MyService from "../../../MyService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { createPortal } from "react-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Form from "../Form/Form";
const Franchisee = () => {
  const [skeletonOn, setSkeletonOn] = useState(true);
  const [contentSection1, setContentSection1] = useState({});
  const [contentSection2, setContentSection2] = useState({});
  const [contentSection3, setContentSection3] = useState({});
  const [contentSection4, setContentSection4] = useState({});
  const [modeldata, setModeldata] = useState('')
  const [showModel,setShowModel]=useState(false)
  const [cards, setCards] = useState([])
  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(()=>{
    const gettingCards=async()=>{
      const response= await MyService.gettingData({pageNumber:5},'CardsGallery')
      // console.log('cards response',response.data.dataItems)
      const Data=response.data.dataItems;
      let cardsData=[]
      Data.forEach(element => {
        cardsData.push(element.data)
      })
      cardsData.sort((a,b)=>a.order-b.order)
      console.log("sort ",cardsData)
      setCards(cardsData)
    }
    gettingCards();
  },[])

  useEffect(() => {
    const GetAbout = async () => {
      const resp = await MyService.gettingData({ pageId: 5 }, "OCMTContent");
      console.log("Franchise response", resp.data.dataItems);
      let sectionItem = resp.data.dataItems;
      sectionItem.forEach((element) => {
        console.log("Element data", element.data.sectionId);
        if (element.data.sectionId === 1) {
          setContentSection1(element.data);
          console.log("content data 1", contentSection1);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 2) {
          setContentSection2(element.data);
          console.log("content data 2", contentSection2);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 3) {
          setContentSection3(element.data);
          console.log("content data 3", contentSection3);
          setSkeletonOn(false);
        } else if (element.data.sectionId === 4) {
          setContentSection4(element.data);
          console.log("content data 4", contentSection4);
          setSkeletonOn(false);
        }
      });
    };

    GetAbout();
  }, []);
  console.log('section 3',contentSection3)
  const handleModel = (value) => {
    if (value === 'one') {
      setModeldata(contentSection2.readMore)
    }
    setShowModel(true);
    console.log('Model', value)
  };

  const handleCloseModal = () => {
    setShowModel(false);
  };
  return (
    <>
    {isOpen && createPortal(
      <div className={Franchisecss.portal}>
        <Form open={setIsOpen} />
      </div>
      , document.getElementById("portal")
    )}
    {showModel && createPortal(
      <div className={Franchisecss.model_overlay}>
        <div class={Franchisecss.model_style}>
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">

                </h1>

                <button
                  type="button"
                  className="btn-close m-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                ></button>
              </div>

              <div className={`modal-body ${Franchisecss.model_body}`} dangerouslySetInnerHTML={{ __html: modeldata }} ></div>
              <hr />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn  px-5 py-2 mx-2"
                  data-bs-dismiss="modal"
                  style={{backgroundColor:'#8b0000',color:'white'}}
                  onClick={handleCloseModal}
                >
                 Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>,

      document.getElementById("portal")
    )}
      <div className="container-fluid px-0">
        <section className={Franchisecss.Stem1_program}>
          <div className="row mx-0">
            <div className="col-md-6 px-0" data-aos="fade-right">
              <div className={Franchisecss.stemImages_box}>
                <div className={Franchisecss.new_box}>
                  {!skeletonOn ? (
                    <img
                      src={MyService.imageConverter(contentSection1.image)}
                      alt="Holistic Information Security Practitioner Institute (HISPI)"
                    />
                  ) : (
                    <Skeleton count={1} className={Franchisecss.new_box} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0" data-aos="fade-right">
              <div className={Franchisecss.Stem_program_box}>
                <div className={Franchisecss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? (
                    contentSection1.heading
                  ) : (
                    <Skeleton
                      count={1}
                      className={Franchisecss.Stem_heading}
                      style={{ height: "50px", width: "90%" }}
                    />
                  )}
                </div>
                <div className={Franchisecss.Stem_text} data-aos="fade-up">
                  {!skeletonOn ? (
                    contentSection1.paragraphText
                  ) : (
                    <Skeleton
                      count={6}
                      className={Franchisecss.Stem_text}
                      style={{ height: "40px", width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={Franchisecss.Store_container}>
          {/* <div className={Franchisecss.Stem_heading} data-aos="fade-up">
            Why Franchise with OCMT?
          </div> */}
          <div className={Franchisecss.store_boxes}>
          {cards.length>0 && cards.map((item,index)=>{
            const {image,text}=item;
              return(

                <div className={Franchisecss.store_box_item} data-aos="fade-up" key={index}>
                  <div className={Franchisecss.store_box_image}>
                    <img src={MyService.imageConverter(image)} alt="Team Meeting" />
                  </div>
                  <div className={Franchisecss.store_box_title}>
                    <div className={Franchisecss.store_boxtext}>
                     {text}
                    </div>
                  </div>
                </div>
              )
          })}
           
          </div>
        </section>
        <section className={Franchisecss.Stem1_program}>
          <div className="row mx-0">
            <div className="col-md-6 order-2 order-md-1 px-0">
              <div className={Franchisecss.Stem_program_box}>
                <div className={Franchisecss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? (
                    contentSection2.heading
                  ) : (
                    <Skeleton
                      count={1}
                      className={Franchisecss.Stem_heading}
                      style={{ height: "50px", width: "90%" }}
                    />
                  )}
                </div>
                <div className={Franchisecss.Stem_text} data-aos="fade-up">
                  {!skeletonOn ? (
                    contentSection2.paragraphText
                  ) : (
                    <Skeleton
                      count={6}
                      className={Franchisecss.Stem_text}
                      style={{ height: "40px", width: "100%" }}
                    />
                  )}
                </div>

                {!skeletonOn ? (
                  <div
                    className={Franchisecss.Stem_button}
                    data-aos="fade-up"
                    data-aos-duration="500"
                    onClick={()=>handleModel('one')}
                  >
                    {contentSection2.button}
                  </div>
                ) : (
                  <Skeleton
                    count={1}
                    className={Franchisecss.Stem_text}
                    style={{
                      height: "50px",
                      width: "30%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 px-0">
              <div className={Franchisecss.stemImages_box}>
                <div className={Franchisecss.new_box} data-aos="fade-up">
                  {!skeletonOn ? (
                    <img
                      src={MyService.imageConverter(contentSection2.image)}
                      alt="home"
                      class={Franchisecss.ammeem}
                    />
                  ) : (
                    <Skeleton count={1} className={Franchisecss.new_box} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="row mx-0">
            <div className="col-12 px-0">
              <div className={`row mx-0 ${Franchisecss.Stem2_program}`}>
                <div className="col-md-6 px-0">
                  {!skeletonOn ? (
                    <Link
                      to="/contact"
                      className={`${Franchisecss.stem_heading3} w-100 h-100`}
                      data-aos="fade-up"
                    >
                      {contentSection3.heading}
                    </Link>
                  ) : (
                    <Skeleton
                      count={2}
                      className={Franchisecss.stem_heading3}
                      style={{
                        height: "50px",
                        width: "60%",
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6 px-0">
                  {!skeletonOn ? (
                    <div className={`${Franchisecss.stem_second_side} w-100`}>
                      <div
                        className={`${Franchisecss.stem_second_text} w-100`}
                        data-aos="fade-up"
                      >
                        {contentSection3.paragraphText}
                      </div>
                      <div className={Franchisecss.stem_second_line}></div>
                      {/* <div className={Franchisecss.stem_second_text} data-aos="fade-up">
                      Read More
                    </div> */}
                    </div>
                  ) : (
                    <Skeleton
                      count={4}
                      className={Franchisecss.stem_heading3}
                      style={{ height: "40px", width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={Franchisecss.Stem1_program} class="CTA">
          <div className="row mx-0">
            <div className="col-md-6 order-2 order-md-1 px-0">
              <div className={Franchisecss.Stem_program_box}>
                <div className={Franchisecss.Stem_heading} data-aos="fade-up">
                  {!skeletonOn ? (
                    contentSection4.heading
                  ) : (
                    <Skeleton
                      count={1}
                      className={Franchisecss.Stem_heading}
                      style={{ height: "50px", width: "90%" }}
                    />
                  )}
                </div>
                <div className={Franchisecss.Stem_text} data-aos="fade-up">
                  {!skeletonOn ? (
                    contentSection4.paragraphText
                  ) : (
                    <Skeleton
                      count={6}
                      className={Franchisecss.Stem_text}
                      style={{ height: "40px", width: "100%" }}
                    />
                  )}
                </div>

                {!skeletonOn ? (
                  // <Link to='/contact' style={{textDecoration:'none'}}>
                  <div className={Franchisecss.Stem_button} data-aos="fade-up"  onClick={()=>setIsOpen(true)}>
                   {contentSection4.button}
                    </div>
                    // </Link>
                ) : (
                  <Skeleton
                    count={1}
                    className={Franchisecss.Stem_text}
                    style={{
                      height: "50px",
                      width: "30%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 px-0">
              <div className={Franchisecss.stemImages_box}>
                <div className={Franchisecss.new_box} data-aos="fade-up">
                  {!skeletonOn ? (
                    <img src={MyService.imageConverter(contentSection4.image)} alt="Meetings" />
                  ) : (
                    <Skeleton count={1} className={Franchisecss.new_box} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Franchisee;
